@import '../../shared/styles/mixins';

body {
  margin: 0;
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  // Have to use font size on body because so much stuff is keyed off of rem. Preference would be #root.
  font-size: 14px;
  @include r($lg) {
    font-size: 16px;
  }
  @include r($xl) {
    font-size: 18px;
  }
}

#root {
  overflow-y: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .wrapper-container {
    overflow-y: auto;
    padding: 1em 1em 0; // Instead of margin to prevent box shadows from being clipped.
    flex: 1;
    width: 100vw;
    align-self: center;
    box-sizing: border-box;
    @include r($lg) {
      padding: 2em 2em 0;
    }
    &.one-column {
      width: 75vw;
    }
  }
}

.wrapper-container > div {
  border-radius: 2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  @include r($xl) {
    border-radius: 2.75rem;
  }
}
.wrapper-container > div > div::after {
  content: " ";
  width: 1px;
  height: 3rem;
  display: block;
}