.versionAndIndicatorContainer {
	display: flex;
}

.docVersionSelectorContainer {
	margin-left: 10px;
	min-width: 325px;
}

.documentContainer {
	& .h-popover {
		left: var(--popover-left) !important;
		top: var(--popover-top) !important;
	}
}

.document-details {
    margin-top: 2em;
    margin-bottom: 2em;
}

/*
 * Styles for displaying user generated content in the document preview.
 */
 .document_open .var__value-missing, .document_open .var_a3d9a401ff07cff669c0ca18636b3fec_datasource:empty {
    background-color: #ffffff;
    border: 1px solid #7F7F7F69;
    color: #EA6854;
    font-weight: 700;
    padding: 0 5px;
    border-radius: 5px;
    box-shadow: 0 1px 4px #00000014;
    margin: 0 4px;
    z-index: 999;
    display: inline-block;
    box-sizing: border-box;
}

.document_open .var_a3d9a401ff07cff669c0ca18636b3fec_default, .document_open .var__value-rte {
    color: #0052CC;
    margin: 0 2px;
    font-weight: 700;
}

.document_open .var_a3d9a401ff07cff669c0ca18636b3fec_datasource {
    color: #000000;
    margin: 0 2px;
    font-weight: 700;
}

.highlightedContent {
	background-color: '#FFFF99';
	cursor: 'pointer';
}

.commentable {
	& .h-popover {
		& span.h-popover-item {
			color: 'white'
		}
	}
}