.ectd__tabs--content-item {
  margin: .5rem 0;
  background: #FAFAFA 0 0 no-repeat padding-box;
  border: 0.5px solid #CDCDCE5F;
  border-radius: 2px;
  padding: 0 .25rem;
  &--title {
    border-bottom: 0.5px solid #CDCDCE5F;
    padding: .25rem;
    span.content-title {
      flex: 1;
      padding-left: .5rem;
    }
  }

  &--tags {
    padding: .25rem;
  }
}