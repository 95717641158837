h2.MuiTypography-root.MuiTypography-h6 {
    font-weight: bold;
    padding-top: 1em;
    font-size: 1.1em;
}

button.component-btn {
    width: 9%;
    padding-bottom: 1em;
    background-color: white !important;
    border: none !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    position: relative;
    margin-bottom: 2em;
    margin-right: 2em;
    cursor: pointer;
}

.search-components {
    margin-bottom: 3em;
}

.element-img {
    position: relative;
    right: 3em;
    top: 2px;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    min-width: 60%;
}

.component-icon {
    position: absolute;
    left: .5em;
    height: 2em;
    width: 2em;
    background-color: #c9c5ff94;
    border-radius: 3px;
}

.element-name {
    font-size: 1.2em;
    color: #2e2e2e;
    position: relative;
    bottom: 3em;
    left: 20%;
}

.element-description {
    font-size: 1em;
    color: #5f5f5f;
    position: relative;
    left: 2.8em;
    bottom: .3em;
}
.dialog-element-container {
    display: flex;
    cursor: pointer;
    padding-top: 8px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
    font-size: 13px;
}
.dialog-element-image-container {
    height: 40px;
    min-width: 40px;
    background-color:#F1F3FA;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialog-element-image {
    margin-top: 2px;
}

.dialog-element-detail-container {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    padding-top: 2px;
}

.dialog-element-title {
    font-weight: 400;
    font-size: 13px;
}

.dialog-element-sub-title {
    font-weight: 500;
    font-size: 12px;
    color: #999999;
    margin-top: 6px;
}

.highlight {
    background-color: #F8F9FC;
    padding-top: 8px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
    border-radius: 4px;
}