.tn__breadcrumbs {
	font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	ul {
		list-style: none;
		margin: .75rem 0 0;
		display: flex;

		li {
			font-size: 1.25rem;
			display: flex;
			align-items: center;
			margin-right: 1.15rem;

			&[data-url] {
				cursor: pointer;
				color: #426AB9;
			}
		}
	}
}