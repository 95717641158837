.sc__modal--slideover {
	&-overlay {
		position: fixed;
		right: -100%;
		top: 0;
		background: rgba(0, 0, 0, 0.65);
		opacity: 1;
		height: 100vh;
		width: 100vw;
		transition: right 700ms ease-in-out;
		z-index: 10;
		&.visible {
			right: 0;
			opacity: 1;
		}
	}
	&-container {
		position: absolute;
		right: -100%;
		top: 0;
		height: 100vh;
		width: 25vw;
		background: #ffffff;
		box-sizing: border-box;
		z-index : 10;
		transition: right 700ms ease-in-out;

		&.visible {
			right: 0;
		}

		&.xsmall {
			width: 25vw;
		}

		&.small {
		  width: 33vw;
		}

		&.medium {
			width: 50vw;
		}

		&.large {
			width: 75vw;
		}

		&.fullscreen {
			width: 100vw
		}

		.preview__wrapper {
			height: 100%;
			&-title, &-footer {
				height: 50px;
				box-sizing: border-box;
				padding: 2rem 1rem;
			}

			&-title {
				border-bottom: 1px solid rgba(0,0,0,0.08);
				align-items: center;
				padding: 2rem 2rem;
				font-size: 2rem;
				font-weight: 700;
			}

			&-footer {
				background: rgba(0, 0, 0, 0.08)
			}

			&-content {
				flex: 1;
				padding: 3rem 4rem;
				box-sizing: border-box;
				overflow: hidden auto;
			}
		}
	}
}