.template-editor {
	height: 100%;
	overflow: hidden auto;
	width: 100%;

	.datasource-table-preview {
		img {
			width: 100%;
		}
	}

	.drag-drop__items {
		padding-bottom: 1.5rem;
	}
	&__elements {
		$parent: &;

		background-color: #FFFFFF;
		padding-bottom: 0;
		padding-top: 0;
		margin-top: 0;
		padding-right: 2.5rem;
		input {
			background: white;
		}

		&-view {
			width: calc(100% - 15px) !important;
			margin: 1rem 0;
			border: 1px solid #7070702f;
			border-radius: .375rem;
			padding: .5rem;
			background-color: white;
		}
		&-nested {
			background-color: #F3F5FB;
			padding-bottom: 0.15rem;
			padding-top: 0.15rem;
			&-nm {
				margin: -.5rem 0 0;
			}
		}

		&-preview {
			padding: 0 0.25rem 0 0;
			margin: .25rem 0 0;
			#{$parent}-view {
				margin: 0;
			}
		}
		&--comment-selected {
			background-color: #ecf16f;
		}
		
		.flex-container{
			display: flex;
			flex:1;
			border: 1px solid #CDCDCE;
		}
		.item-container{
			flex:1;
			padding: 0.5rem;
		}
		.editor-item-text-input {
			width: 100%;
			border: 0;
			outline: none;
			font-size: 1em;
			background-color: #FFFFFF;
		}
		.element-container {
			border: none;
			border-radius: 4px;
			position: relative;
			margin-left: 1.5rem;
			margin-bottom: 0px;
			padding-bottom: 0px;
		}

		.element-readonly-container {
			position: relative;
		}

		.title-container {
			position: relative;
			width: 20%;
			border-radius: 0.75rem;
			padding:0.85rem;
		}
		.title-as-image {
			display: block;
			width: 0.95rem;
			height: 0.95rem;

		}

		.title-text {
			color: white;
			font-size: 0.75em;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			text-align: center;
		}

		.element-container #flex-buttons-container,
		.element-readonly-container #flex-buttons-container
		{
			display: none;
		}

		.element-container:hover #flex-buttons-container,
		.element-readonly-container:hover #flex-buttons-container
		{
			display: flex;
			justify-content: flex-end;
		}

		.MuiTable-root {
			.MuiTableHead-root {
				.MuiTableRow-root {
					.MuiTableCell-root {
						z-index: 0;
					}
				}
			}
		}

	}

	.tox .tox-editor-header {
		z-index: 0;
	}
}