.cm-tableType-preview {
    color: #000000;
    font-weight: 700;
    font-size: 20px;
    position: relative;
    padding: 2px;
    border: 1px solid transparent;
    min-height: 24px;
    padding-top: 10px;
}

.ittTable {
    table {
        font-size: 14px !important;
        border: 1px solid black;
        border-bottom: none;
        border-collapse: collapse;
        font-family: 'Times New Roman', Times, serif;
    }

    caption {
        margin: 10px;
        font-size: 18px !important;
        text-align: left;
        font-weight: 700;
    }

    th {
        font-weight: bold;
    }

    td,
    th {
        font-size: 14px !important;
        text-align: center;
        vertical-align: top;
        border: 1px solid black;
        border-collapse: collapse;
    }

    td:first-child,
    th:first-child {
        text-align: left !important;
        border-left: none
    }

    td:last-child,
    th:last-child {
        border-right: none
    }
}

.ittTableLoading {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    padding: 10px;
}

.ittTableName {
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
}