.ectd__modal--addcompound {
  width: 100%;
  ul {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;

	&.ectd__modal--addcompound-list {
	  margin-top: 2rem;
	  max-height: 300px;
	  overflow: hidden auto;
	  li.ectd__modal--addcompound-list-item {
		height: 60px;
		display: flex;
		width: 100%;
		align-items: center;
		padding: 0 calc(2rem + 20px);
		box-sizing: border-box;
		cursor: pointer;
		&:nth-child(odd) {
		  background: #F1F3FA4D;
		}
		&.active {
		  background: #EDF2EF;
		  padding: 0 2rem;
		}

		span {
		  color: #000000;
		  margin-left: 1rem;
		  text-decoration: underline;
		}
	  }
	}

	&.ectd__modal--addcompound-details {
	  margin-top: 2rem;
	  li.ectd__modal--addcompound-details-item {
		justify-content: space-between;
		display: flex;
		width: 100%;
		padding: .5rem 0;
		&:nth-child(odd) {
		  border-bottom: 1px #7F7F7F4D solid;
		}
		span {
		  color: #000000;
		  font-weight: 700;
		}
	  }
	}

  }
}