.ectd__list--moduleTable {
  width: calc(100% - 4rem);
  border-collapse: collapse;
  margin: 2.5rem 2rem;
  box-sizing: border-box;

  thead {
	th {
	  text-align: left;
	  height: 40px;
	}
  }
  tbody {
	tr {
	  height: 40px;
	  td:not(:first-child) {
		border-bottom: 1px solid #dbe3f1;
	  }
	}
  }
}