.tm__html-preview {
	img {
		width: 100%;
	}
	.var_a3d9a401ff07cff669c0ca18636b3fec_default, .var__value-rte {
		color: #0052CC;
		margin: 0 2px;
		font-weight: 700;
	}

	.var_a3d9a401ff07cff669c0ca18636b3fec_datasource {
		color: #000000;
		margin: 0 2px;
		font-weight: 700;
		&:empty:before {
			content: 'Datasource'
		}
	}

	.var_a3d9a401ff07cff669c0ca18636b3fec_datasource:empty, .var-missing {
		background-color: #ffffff;
		border: 1px solid #7F7F7F69;
		color: #EA6854;
		padding: .25rem .5rem;
		font-weight: 700;
		border-radius: 5px;
		box-shadow: 0 1px 4px #00000014;
		margin: 0 4px;
		z-index: 999;
		display: inline-block;
		box-sizing: border-box;
	}


}