.notification__snackbar {
	&__content {
		display: flex;
		flex-direction: column;
		&--message {
			display: flex;
			align-items: center;
			svg {
				color: #CDCDCE;
				margin-right: 5px;
			}
		}
	}
}