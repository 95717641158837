.ectd__tbrp {
  &--title {
	color: #333333B3;
	font-size: 1.25rem;
	flex:1;
	text-align: center;
  }

  &--modules {
	list-style: none;
	margin: 0;
	padding: 1rem 0 0 0;
	&-list {
	  display: flex;
	  flex-direction: column;
	  cursor: pointer;
	  align-items: center;
	  color: #333333;
	  font-weight: bold;
	  font-size: 1rem;
	  padding: 0 1rem;
	  margin: .5rem 0;
	  svg {
		margin-right: .75rem;
		cursor: pointer;
	  }
	  &__number {
		margin-right: 1rem;
	  }

	  &__title {
		flex: 1
	  }

	  &.active {
		background: #FCE8E5 0 0 no-repeat padding-box;
		color: #000000;
		border-radius: .5rem;
	  }
	}
  }

  &--subsections {
	list-style: none;
	margin: 1rem 0;
	padding: 0.5rem 0.25rem;
	box-shadow: inset 0 3px 6px #00000029;
	border-radius: 1rem;
	width: 100%;
	&-list {
	  font-weight: normal;
	  font-size: .875rem;
	  flex-direction: row;
	  padding: 0 1rem;
	  margin: .5rem 0;

	  &__number {
		margin-right: 1rem;
	  }

	  &__title {
		flex: 1
	  }
	}
  }
}