.mtpl__comment-highlight {

	::selection {
		background-color: #e9ed76;
	}

	em {
		background-color: #e9ed76;
	  	cursor: pointer
	}


}