.sci__dms {
  display: flex;
  flex-direction: column;
  &--input {
	height: 55px;
	width: 100%;
	line-height: 1.4375em;
	box-sizing: border-box;
	padding: 16.5px 14px;
	border: 1px solid #4D72BD80;
	border-radius: 4px;
	position: relative;
	cursor: pointer;

	p {
	  margin: 0;
	  color: #333333B3;
	}

	label {
	  position: absolute;
	  top: -13px;
	  padding: 2px 5px;
	  background-color: white;
	  font-size: .75rem;
	  color: #00000099;
	  &.active {
		color: #426AB9;
	  }

	}
  }

  ul.sci__dms--options {
	margin: 0;
	list-style: none;
	padding: 0;
	box-sizing: border-box;
	width: 100%;
	background: white;

	li {
	  box-sizing: border-box;
	  width: 100%;
	  display: flex;
	  align-items: center;
	  padding: .7rem 1rem;
	  cursor: pointer;
	  color: #00000080;
	  svg {
		margin-right: .5rem;
	  }
	}
  }
}