.citations-preview {
    display: flex;
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    padding: 2px;
    border: 1px solid transparent;
    min-height: 24px;
    padding-top: 10px;
}