.ectd__sd {
  &--lp {
	&-title {
		color: #000000;
	  	font-weight: 500;
	  	font-family: Montserrat, Helvetica, Arial, sans-serif;
	  	font-size: 1.25rem;
	  	margin-left: 1.5rem;
	}
  }
}