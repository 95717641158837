.tm__preview--editors {
	label.label {
		font-size: 1rem;
		color: #7F7F7F;
		margin-bottom: .5rem;
		margin-left: .25rem;
	}

	.preview-value {
		font-size: 1rem;
		margin-bottom: 0.75rem;
		margin-left: 0.25rem;
		margin-top: 0;
	}

	.rte__wrapper {
		width: 100%;
		border: 1px solid rgba(0, 0, 0, 0.23);
		border-radius: 4px;


		.tox-tinymce {
			border-radius: 4px;
		}
	}
}