.ectd__modal--contentpicker {
  &-list {
	list-style: none;
	padding: 0;
	margin: 1rem 0;
	width: 100%;
	&_item {
	  display: flex;
	  cursor: pointer;
	  border-bottom: 1px solid rgba(66, 106, 185, 0.06);
	  width: 100%;
	  justify-content: center;
	  flex-direction: column;
	  padding: 0.5rem 1rem;
	  box-sizing: border-box;
	  &:nth-child(even) {
		background: rgba(66, 106, 185, 0.06);
	  }

	  &__meta {
		margin: .25rem 0 .5rem 2rem;
		box-sizing: border-box;
		span {
		  font-size: .75rem;
		  color: rgba(0, 0, 0, 0.5);
		}

		&-tags {
		  &__tag {
			border-radius: .5rem;
			padding: 5px 15px;
			box-sizing: border-box;
			color: white !important;
			font-size: .875rem;
			background: #4D72BD 0 0 no-repeat padding-box;
			margin: .25rem;

			&.version {
			  background: black;
			}

			&.compound {
			  background: #80CAFF;
			}
		  }

		}
	  }

	  &__title {
		padding: 1rem;
		font-size: .875rem;
		font-weight: 600;
		word-break: break-all;
	  }
	}
  }

  &-internal {
	width: 100%;
  }

  &-external {
	&--breadcrumbs {
	  ul {
		list-style: none;
		display: flex;
		padding: 0;
		margin: 1rem 0;

		li {
		  display: flex;
		  cursor: pointer;
		}
	  }
	}
  }
}