.ectd__tabs--pcdl {
  width: 100%;
  margin: 1rem 0;
  &-box {
	border-radius: 4px;
	min-height: 52px;
	padding: 0 1rem;
	box-sizing: border-box;
	width: 100%;
  }
  &-compound {
	width: 100%;
	input {
	  font-weight: bold;
	  color: #37373B
	}
  }

  &-indications {
	position: relative;
	margin-top: 1rem;
	label {
	  color: rgba(0, 0, 0, 0.38);
	  font-size: .875rem;
	  background: white;
	  position: absolute;
	  top: 0;
	  left: 0;
	  transform-origin: top left;
	  padding: 0 5px;
	  line-height: 1.4375em;
	  transform: translate(14px, -9px) scale(0.75);
	}
	&__container {
	  padding: 1rem;
	  width: 100%;
	  box-sizing: border-box;
	  border-color: rgba(0, 0, 0, 0.26);
	  border-width: 1px;
	  border-style: solid;
	  border-radius: 4px;
	  display: flex;
	  flex-wrap: wrap;
	  &-indication {
		border-radius: 100px;
		padding: 5px 15px;
		box-sizing: border-box;
		color: white;
		font-size: .875rem;
		background: #4D72BD 0 0 no-repeat padding-box;
		margin: .25rem;
		flex: 1 1 1;
	  }
	}
  }

}