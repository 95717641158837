.ectd__tblp {
  width: 100%;
  ul {
	list-style: none;
	margin: 0;
	padding: 1rem 0 0 0;
	li {
	  display: flex;
	  cursor: pointer;
	  align-items: center;
	  color: #00000099;
	  font-weight: bold;
	  padding: 0 1rem;
	  span:nth-child(1) {
		margin-right: .75rem;
	  }

	  span:nth-child(2) {
		flex: 1
	  }

	  &.active {
		background: #FCE8E5 0 0 no-repeat padding-box;
		color: #000000;
		border-radius: .5rem;
	  }
	}
  }

  &--moduleTitle {
	color: #EA6854;
	margin-left: 1rem;
  }

  h2 {
	margin: 0 0 0 1rem;
	color: #EA6854;
	font-size: 1.15rem;
  }
}
