@mixin center-element {
	display: flex;
	justify-content: center;
	align-items: center;
}

.comments-tab {
	// @include center-element;
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;

	&__selector {
		display: flex;
		flex: 0;
		justify-content: space-evenly;
		align-items: center;
		box-shadow: inset 0 0 10px #e1e2e3;
		height: 32px;
		width: 100%;
		border-radius: 100px;
		margin-top: 16px;
		margin-bottom: 20px;

		&--count-bubble {
			@include center-element;
			height: 20px;
			width: 20px;
			font-size: .65rem;
			margin: 5px;
			border-radius: 50%;
			color: white;
			background-color: #426ab9;
		}

		&--button {
			@include center-element;
			outline: none;
			border: none;
			font-family: "Lato", "Helvetica", "Arial", sans-serif;
			padding-left: 10px;
			border-radius: 100px;
			box-shadow: inset 0 0 10px #e1e2e3;
			opacity: 0.2;

			&-selected {
				display: flex;
				justify-content: center;
				align-items: center;
				outline: none;
				font-family: "Lato", "Helvetica", "Arial", sans-serif;
				color: #1F212C;
				height: 24px;
				border-radius: 100px;
				border: solid 1px #e5e6e7;
				box-shadow: inset 0 0 10px white;
				opacity: 1;
				padding-left: 10px;
			}
		}

		&--label {
			width: 90px;
		}
	}

	&__selected-view {
		display: flex;
		width: 100%;
		flex-direction: column;
		flex: 1;
		overflow: scroll;
	}

	&__hide {
		display: none;
	}

	&__reply {
		width: 100%;
		margin-bottom: 20px;

		button {
			background-color: #426AB9;
			color: white;
			height: 40px;
			font-family: "Lato", "Helvetica", "Arial", sans-serif;
			font-size: 16px;
			border-radius: 4px;
			width: 100%;
		}
	}
}

.comments-individual {
	display: flex;
	border-bottom: solid #7070702F 1px;
	padding: .5rem;

	&__actual {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		width: 100%;

		&-clickable {
			cursor: pointer;
		}

		&-highlight {
			background-color: #ecf16f;
			color: black;
			max-width: 100%;
		}



		& > div {
			margin-bottom: 10px;
		}

		&--recorded-user {
			color: #426AB9;
		}

		&--recorded-date {
			margin-bottom: 10px;
			color: var(---707070);
			font-size: 10px;
		}
	}

	&-selected {
		background-color:  #e9ed76;

		& .comments-individual__actual-highlight {
			background-color: rgba(0, 0, 0, 0.08);
		}
	}

	&__menu {
		margin: 0;
	}

	&__reply {

		&--single {
			display: flex;
		}
		&--multiple {
			display: flex;
			justify-content: space-between;
		}

		width: 100%;
		margin: .5rem 0 1.5rem 0;

		&-button {
			background-color: #426AB9;
			color: white;
			height: 40px;
			font-family: "Lato", "Helvetica", "Arial", sans-serif;
			font-size: 16px;
			border-radius: 4px;
			// width: 100%;
			width: 25%;
		}

		&-expand {
			background-color: gray;
			color: white;
			height: 40px;
			font-family: "Lato", "Helvetica", "Arial", sans-serif;
			font-size: 16px;
			border-radius: 4px;
		}

		&-value {
			background-color: #edf0f9;
		}
	}
}

.comments-tab-buttons-view {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}