.ectd__sdcp {
  margin: 1.5rem;
  height: calc(100% - 3rem);
  position: relative;

  &-table {
    position: relative;
    height: 100%;
    overflow: hidden auto;
  }
}