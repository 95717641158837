.ectd__list--moduleNavigation {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  li {
	margin: 0 1rem;
	font-weight: 700;
	color: #37373B;
	cursor: pointer;
	&.active {
	  background: #FBFCFE 0 0 no-repeat padding-box;
	  box-shadow: inset 0 3px 6px #00000029;
	  padding: 10px 30px;
	  border-radius: 100px;
	  color: #EA6854;
	}
  }
}