.mh__statusbar {
	&-container {
		padding: 0 .5rem 0 1rem;
		height: 2.5rem;
		border-radius: 1.25rem !important;
		display: flex;
		align-items: center;
		cursor: pointer;
		box-sizing: border-box;
		p {
			line-height: 0;
			font-weight: 700;
			margin-left: .25rem;
		}
	}
}