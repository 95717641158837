.ectd__list--tocItem {
  list-style: none;
  padding: 0 0 0 .25rem;
  margin: 0;
  &.inset {
	margin: 1rem 0;
	padding: 0.5rem 0 0 0.25rem;
	box-shadow: inset 0 3px 6px #00000029;
	border-radius: 1rem;
  }
  width: 100%;
  &-item {
	font-weight: normal;
	font-size: .75rem;
	flex-direction: row;
	padding: 0 0 0 .5rem;
	margin: .5rem 0;

	&__number {
	  margin-right: .75rem;
	  font-family: Montserrat, Helvetica, Arial, sans-serif;
	}

	&__title {
	  flex: 1;
	  font-family: Montserrat, Helvetica, Arial, sans-serif;
	}

	&__number, &__title {
	  &--active {
		color: #FF715B;
	  }
	}
  }
}

.ectd__list--toc {
  &--modules {
	list-style: none;
	margin: 0;
	padding: 1rem 0 0 0;

	&-list {
	  display: flex;
	  flex-direction: column;
	  cursor: pointer;
	  align-items: center;
	  color: #333333;
	  font-weight: bold;
	  font-size: 1rem;
	  padding: 0;
	  margin: .5rem 0;

	  svg {
		margin-right: .75rem;
		cursor: pointer;
	  }

	  &__number {
		margin-right: 1rem;
		font-family: Montserrat, Helvetica, Arial, sans-serif;
	  }

	  &__title {
		flex: 1;
		font-family: Montserrat, Helvetica, Arial, sans-serif;
	  }

	  &.active {
		background: #FCE8E5 0 0 no-repeat padding-box;
		color: #000000;
		border-radius: .5rem;
	  }
	}
  }
}