.input-element {
    margin-bottom: 16px;
}

.template-element-dialog-container {
    height: 45vh;
    flex: 70%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.data-container {
    flex: 70%;
    overflow: scroll;
}

.dialog-action-buttons {
    flex: 0.6;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 10%;
}

.dialog-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 24px;
}

.dialog-subtitle {
    font-size: 13px;
}

.recents-dialog {
    margin-top: -16px;
    overflow: scroll;
}

.template-element-title {
    font-weight: bold;
}

.dialog-element-sub-title-switch {
    font-size: 11px;
    padding-top: 6px;
}

.all-elements-container {
    width: 100%;
}

.tree-view-progress {
    padding-left: 50%;
    padding-top: 25%;
}