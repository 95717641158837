.ectd__acb {
  ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	li {
	  margin: 0 .25rem;
	  background: #CDCDCE5F 0 0 no-repeat padding-box;
	  border: 1px solid #CDCDCE5F;
	  padding: .25rem 1rem;
	  border-radius: 100px;
	  font-size: .85rem;
	  font-weight: 700;
	}
  }

  p {
	margin-left: .5rem;
  }
}