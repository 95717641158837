@import '../../../styles/mixins';

.masthead__global {
	flex: 0;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	left: 0;
	right: 0;
	z-index: 1;
	box-sizing: border-box;
	width: 100%;

	@include r($xl) {
		padding: 2rem;
	}

	&-customview {
		display: flex;
		z-index: 1;
		align-items: center;
	}

	&-container {
		flex-grow: 1;
		justify-content: flex-end;
		flex-direction: row;
		display: flex;
		align-items: center;
		gap: 1em;
		color: #89898A;
	}

	&-breadcrumbs {
		display: flex;
		z-index: 1;
		align-items: flex-start;
	}

	&-orb {
		display: flex;
		position: relative;
		border-radius: 85px;
		height: 85px;
		width: 85px;
		box-shadow: rgb(0 0 0 / 30%) 0px 2px 3px;
		.ButtonBar-customLink-11 li div span {
			font-size: .7rem !important;
		}
		.vertical-context-menu {
			width: 85px !important;
			z-index: 1 !important;
			flex-direction: column !important;
			padding: 85px .5em 2rem !important;
			overflow-x: unset !important;
			box-sizing: border-box !important;
			> div > div {
				display: flex;
				flex-direction: column;
				gap: .3em;
			}


		}
		.horizontal-action-menu {
			height: 85px;
		}
		.context-menu-item {
			width: 100%;
		}

		.context-menu-item + .context-menu-item {
			margin-top: .2em;
		}
		/* All .MuiButton-root styling is for old context buttons. Once those are gone you can remove them from this code. */
		.MuiButton-root {
			margin-top: .2em;
		}

		.context-menu-item > button, .MuiButton-root {
			padding: .75em;
			border-radius: .6rem;
			color: #fff;
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;
			width: 100%;
			text-align: center;
			font-size: .7rem !important;
			text-transform: none;
			line-height: unset; // wtf?
			gap: .2em;
			&[aria-disabled], &[disabled] {
				background-color: rgba(255,255,255,.2);
				border: rgba(255,255,255,.2) 1px solid;
				color: #fff !important;
				opacity: .5;
				cursor: not-allowed;
			}
			svg {
				fill: #fff;
			}
			&:hover, &.active {
				background-color: #fff;
				color: #000;
				svg {
					fill: #000;
				}
			}
		}
		@include r($xl) {
			border-radius: 105px;
			height: 105px;
			width: 105px;
			.ButtonBar-customLink-11 li div span {
				font-size: .875rem !important;
			}
			.vertical-context-menu {
				width: 105px;
			}
			.horizontal-action-menu {
				height: 105px;
			}
		}
	}
}
