.scl__ncb {
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #CDCDCE5F;
  border-radius: 1.5rem;
  opacity: 1;
  margin: 1rem 0;

  &--children {
    margin: 0 2.5rem;
  }


  &--header {
    display: flex;
    border-bottom: 1px solid #E7EBF7C1;
    margin: 2rem 2.5rem;
    padding-bottom: 1rem;
    &--number {
      border-radius: 50%;
      color: #FFFFFF;
      background: #00000066;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .75rem;
      font-weight: bold;
      margin-right: 1rem;
    }

    &--title {
      margin: 0;
      color: #00000066;
    }
  }

  &.enabled {
    background: #FBFCFE 0 0 no-repeat padding-box;
    border: 1px solid #CDCDCE5F;

    .scl__ncb--header {
      &--number {
        background: #426AB9;
      }
      &--title {
        color: #426AB9;
      }
    }
  }

}